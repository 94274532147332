import { Injectable, NgZone } from '@angular/core';
import { INGXLoggerMetadata, NGXLoggerServerService } from 'ngx-logger';
import { StateService } from './state.service';
import { HttpBackend } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CustomLoggerService extends NGXLoggerServerService  {

  constructor(
    httpBackend: HttpBackend,
    ngZone: NgZone,
    private _stateService: StateService
  ) {
    super(httpBackend, ngZone);
  }

  public customiseRequestBody(metadata: INGXLoggerMetadata): any
  {
    let body: any = { ...metadata };

    body['subscription'] = this._stateService.state.subscriptionSlug;
    body['uniqueId'] = this._stateService.state.uniqueId;

    return body;
  }

}
