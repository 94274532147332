import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import packageJson from '../../package.json';

rg4js.default('apiKey', 'klEdlKrwrzXOc9wVJ7Q8rQ');
rg4js.default('setVersion', packageJson.version);
rg4js.default('enableCrashReporting', true);

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {

  constructor(
    private _logger: NGXLogger
  ) { }

  handleError(e: any): void {
    this._logger.error(e);

    if (environment.production) {
      rg4js.default('send', {
        error: e,
      });
    }
  }
}
