import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';
import { AppSettingsService } from './modules/app-settings/app-settings.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private _appSettingsService: AppSettingsService,
    private _logger: NGXLogger
  ) {
    this._logger.trace('AppComponent');
  }

  private _loadSettings() {
    this._appSettingsService.getSettings()
      .pipe(take(1))
      .subscribe(settings => {
        this._logger.trace('AppComponent | loadSettings', settings);
      });
  }

  ngOnInit() {
    this._logger.trace('AppComponent | onInit');
    this._loadSettings();
  }

}
