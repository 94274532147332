{
  "name": "client-app",
  "version": "4.8.4",
  "scripts": {
    "ng": "ng",
    "start": "run-script-os",
    "prestart": "node aspnetcore-https",
    "start:windows": "ng serve --ssl --ssl-cert \"%APPDATA%\\ASP.NET\\https\\%npm_package_name%.pem\" --ssl-key \"%APPDATA%\\ASP.NET\\https\\%npm_package_name%.key\" --host=127.0.0.1",
    "start:default": "ng serve --ssl --ssl-cert \"$HOME/.aspnet/https/${npm_package_name}.pem\" --ssl-key \"$HOME/.aspnet/https/${npm_package_name}.key\" --host=127.0.0.1",
    "build": "ng build"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "bootstrap": "5.3.1",
    "luxon": "^2.1.1",
    "ng-recaptcha": "^13.2.1",
    "ngx-logger": "^5.0.12",
    "raygun4js": "^2.22.3",
    "rxjs": "~6.6.0",
    "tslib": "^2.1.0",
    "uuid": "^9.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/build": "^18.2.5",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@types/luxon": "^2.0.7",
    "@types/node": "^12.11.1",
    "@types/raygun4js": "^2.13.6",
    "@types/uuid": "^9.0.1",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "eslint": "^8.57.0",
    "run-script-os": "^1.1.6",
    "typescript": "~5.5.4"
  }
}
