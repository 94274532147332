import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { keys } from 'src/environments/keys';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RaygunErrorHandler } from './app.raygun';
import { PhoneValidatorDirective } from './validators/phone/phone-validator.directive';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppLetDirective } from './directives/app-let';
import { RouterModule } from '@angular/router';
import { CanActivateUser } from './route-guards/can-activate-user';
import { CustomLoggerService } from './services/logger.service';


@NgModule({ declarations: [
        AppComponent,
        AppLetDirective,
        PhoneValidatorDirective,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        RecaptchaV3Module,
        LoggerModule.forRoot({
            level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.TRACE,
            serverLoggingUrl: '/api/logs',
            serverLogLevel: NgxLoggerLevel.DEBUG
        }, {
            serverProvider: {
                provide: TOKEN_LOGGER_SERVER_SERVICE,
                useClass: CustomLoggerService
            }
        }),
        SpinnerComponent,
        RouterModule.forRoot([
            { path: 'error', loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule) },
            { path: ':subscription', loadChildren: () => import('./modules/subscriptions/subscription.module').then(m => m.SubscriptionModule) },
            { path: '**', redirectTo: '/error/404' }
        ])], providers: [
        { provide: ErrorHandler, useClass: RaygunErrorHandler },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: keys.recaptcha },
        httpInterceptorProviders,
        CanActivateUser,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
