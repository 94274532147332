import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './api.interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { BranchInterceptor } from './branch.interceptor';
import { SubscriptionInterceptor } from './subscription.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SubscriptionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BranchInterceptor, multi: true }
];
