import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { AppService } from '../app.service';

@Injectable({ providedIn: 'root' })
export class BranchInterceptor implements HttpInterceptor {

  constructor(
    private _appService: AppService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._appService.branch$.pipe(
      first(),
      mergeMap(branch => {
        if (!!branch) {
          req = req.clone({ setHeaders: { 'x-esalon-branch': branch.id.toString() }});
        }

        return next.handle(req);
      })
    );
  }

}
